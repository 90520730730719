export const enum ModalKey {
  NOTIFY_ME = 'NOTIFY_ME',
  DEV_MODAL = 'DEV_MODAL',
  FAKEDOOR_MODAL = 'FAKEDOOR_MODAL',
  CONTINUOUS_AVAILABILITY = 'CONTINUOUS_AVAILABILITY',
  JOBAUTO_WHITEPAPER_MODAL = 'WHITEPAPER_MODAL',
  CAR_NOT_AVAILABLE = 'CAR_NOT_AVAILABLE',
  CAR_DETAILS = 'CAR_DETAILS',
  DELIVERY_FEE = 'DELIVERY_FEE',
  PAYMENT_DATA_INFO = 'PAYMENT_DATA_INFO',
  PREQUALIFY = 'PREQUALIFY',
  EMPTY_CART = 'EMPTY_CART',
  RETURN_TO_CHECKOUT = 'RETURN_TO_CHECKOUT',
  LOGIN = 'LOGIN',
  BUSINESS_ACCOUNT_DETECTED = 'BUSINESS_ACCOUNT_DETECTED',
  CONFIGURE_VEHICLE = 'CONFIGURE_VEHICLE',
  DATE_PICKER_MODAL = 'DATE_PICKER_MODAL',
  RETENTION_MODAL = 'RETENTION_MODAL',
  PROLONGATION_MODAL = 'PROLONGATION_MODAL',
  INFO_MODAL = 'INFO_MODAL',
  LOW_CONFIDENCE_MODAL = 'LOW_CONFIDENCE_MODAL',
  HOW_LOYALTY_WORKS = 'HOW_LOYALTY_WORKS',
  PDP_CAR_INFO = 'PDP_CAR_INFO',
  INSURANCE_UPGRADE = 'INSURANCE_UPGRADE',
  VERIFICATION_FAILED_ERROR_MODAL = 'VERIFICATION_FAILED_ERROR_MODAL',
  CANCELLATION_ERROR_MODAL = 'CANCELLATION_ERROR_MODAL',
  REGISTRATION_CERTIFICATE_MODAL = 'REGISTRATION_CERTIFICATE_MODAL',
  DELIVERY_OR_PICKUP_FAKEDOOR_MODAL = 'DELIVERY_OR_PICKUP_FAKEDOOR_MODAL',
  COMPARISON_MODAL = 'COMPARISON_MODAL',
}

export const enum ModalHeaderVariant {
  SMALL = 'SMALL',
}

export const MODAL_UNMOUNT_DELAY_MS = 250;
