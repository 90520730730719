import { useVoucherStore, VoucherClass } from '@finn/ua-vehicle';
import {
  clearClientCookie,
  CookieKeys,
  getClientCookie,
  traceabilityHeadersBrowser,
  useCurrentLocale,
} from '@finn/ui-utils';
import { useEffect } from 'react';

import { getVoucherByHash } from '~/services/voucher';

const VoucherParam = 'VoucherCode';

export const useProcessVoucherParam = () => {
  const setVoucherData = useVoucherStore(
    (state) => state.setReferralVoucherData
  );
  const clearReferralVoucher = useVoucherStore(
    (state) => state.clearReferralVoucher
  );
  const { locale } = useCurrentLocale();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const voucherInCookie = getClientCookie(CookieKeys.VOUCHER_CODE);
    const voucherHash = urlParams.get(VoucherParam) || voucherInCookie;

    if (voucherHash) {
      getVoucherByHash(voucherHash, locale, traceabilityHeadersBrowser())
        .then((data) => {
          if (data.status === 200 && data.is_valid) {
            setVoucherData(data);
            // CUG_ID and closed_user_group are mutually exclusive
            if (data.class === VoucherClass.closed_user_group) {
              clearClientCookie(CookieKeys.CLOSED_USER_GROUP_ID);
            }
          } else {
            clearReferralVoucher();
          }
        })
        .catch(() => {
          clearReferralVoucher();
        });
    }
    // run once
  }, []);
};
