import { REFERRAL_VOUCHER_COOKIE_EXPIRATION } from '@finn/ua-constants';
import { useVoucherStore } from '@finn/ua-vehicle';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ui-modules';
import {
  CookieKeys,
  getClientBooleanCookie,
  setClientBooleanCookie,
} from '@finn/ui-utils';
import { useEffect } from 'react';

export const useReferralTracker = () => {
  const referralVoucherData = useVoucherStore(
    (state) => state.referralVoucherData
  );

  useEffect(() => {
    if (!referralVoucherData) {
      return;
    }
    const isReferralVoucherTracked = getClientBooleanCookie(
      CookieKeys.IS_REFERRAL_VOUCHER_TRACKED
    );

    if (!!referralVoucherData && !isReferralVoucherTracked) {
      setClientBooleanCookie(
        CookieKeys.IS_REFERRAL_VOUCHER_TRACKED,
        true,
        REFERRAL_VOUCHER_COOKIE_EXPIRATION
      );
      try {
        interactionTrackingEvent(
          TrackingEventName.REFERRAL_VOUCHER,
          referralVoucherData
        );
      } catch (error) {
        console.error('Error parsing or decoding referral voucher', error);
      }
    }
  }, [referralVoucherData]);
};
