import { useVoucherStore } from '@finn/ua-vehicle';
import { useEffect } from 'react';

// some stores need to be initialized on client side (e.g. for setting initial values from browser cookies)
export const useInitStoresOnClient = () => {
  const initVoucherStore = useVoucherStore(
    (state) => state.initFromBrowserCookie
  );
  useEffect(() => {
    initVoucherStore();
  }, [initVoucherStore]);
};
